import { query } from "firebase/database"

/* eslint-disable node/prefer-global/process */
export default defineNuxtRouteMiddleware(async (from, to) => {
    const firebaseUser = await ensureFirebaseUser()
    if (!firebaseUser) {
        return navigateTo('/login?redirect=' + to.fullPath)
    }

    await ensureApolloInitialized(firebaseUser)

    if (process.client) {
        const userResult = await ensureCiviqaUserOnClient()
        const queryParams = to.query ? { ...to.query } : {}
        queryParams['redirect'] = to.fullPath
        if (userResult.email) {
            queryParams['email'] = userResult.email
        }
        const queryParamsAsString = Object.keys(queryParams).map(key => key + '=' + queryParams[key]).join('&')

        if (userResult.missingEnviroPerson) {
            return navigateTo('/request?' + queryParamsAsString)
        }
        if (userResult.error) {
            const message = encodeURIComponent(userResult.error)
            return navigateTo('/problems')
        }
        if (!userResult.civiqaUser) {
            return navigateTo('/problems')
        }
    }
})
